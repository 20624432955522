// MAIN.JS
jQuery(function($) {

	/**********************************************/

	var notify = function(msg) {
		setTimeout(function(msg) {
			var div = "<div class='notify'>" + msg + "</div>"
			var n = $(div).appendTo("body");
			setTimeout(function() { n.remove(); }, 2500);
		}, 250);
	};

	if ($.fn.scrollintoview) {
		$("a.clickme").click(function(e){
			e.preventDefault();
			$(".scrollintoview").scrollintoview({ duration: 5000, complete: notify });
		});
	}

	/**********************************************/

	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {
				// Activer ceci pour faire l'inverse lors que l'element sort de la partie visible
				// $(this).css("opacity", 0);
			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/
	// JQUERY.MASKEDINPUT
	if ($.fn.mask) {
		$("input[type='tel']").mask("(999) 999-9999? x99999");
		$("input[type='text'].postalcode").mask("a9a 9a9", {
			completed:function () {
				this.val(this.val().toUpperCase());
			}
		});
	}

	/**********************************************/


	/**********************************************/

	$slideshow = $('.slideshow');
	var initSlideshow = function() {
		return setInterval(function() {
			$slides = $slideshow.find('.slide');
			$active = $slideshow.find('.slide.active');
			$next = $active.next();

			if ($next.length > 0) {
				$active.removeClass('active');
				$active.addClass('transiting');
				$active.next().addClass('active');
			}
			else {
				$active.removeClass('active');
				$active.addClass('transiting');
				$slides.first().addClass('active');
			}
			setTimeout(function() {
				$active.removeClass('transiting');
			}, 1500);
		}, 3000);
	}

	$(document).ready(function() {
		var slideshowTimer = initSlideshow();
	});


	/**********************************************/
/*	$layerslider = $('#layerslider');
	$layerslidermobile = $('#layerslider-mobile');

	$layerslider.layerSlider({
		responsive: true,
		pauseOnHover: false,
		navPrevNext: true,
		navStartStop: false,
		hoverPrevNext: true,
		navButtons: true,
		keybNav: true,
		showCircleTimer: false,
		thumbnailNavigation: 'disabled',
		skinsPath: 'css/layerslider/skins/',
		autoStart: true
	});

	$layerslidermobile.layerSlider({
		responsiveUnder: 480,
		hideOver: 480,
		layersContainer : 480,
		navButtons: false,
		pauseOnHover: false,
		navPrevNext: false,
		navStartStop: false,
		hoverPrevNext: false,
		keybNav: false,
		showCircleTimer: false,
		thumbnailNavigation: 'disabled',
		skinsPath: 'css/layerslider/skins/',
	});

	$(".slideshow-next").click(function() {
		$layerslider.layerSlider('next');
		$layerslidermobile.layerSlider('next');
	});
	$(".slideshow-prev").click(function() {
		$layerslider.layerSlider('prev');
		$layerslidermobile.layerSlider('prev');
	});
*/
	/**********************************************/

	// MENU
	var menu = ".menu-mobile";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}

	/**********************************************/



	// PARALLAX
	function parallax() {
		var scroll = $(window).scrollTop();
		// alert(scroll);

		if (scroll < 700) {
			translation = "translateX(-100vw)";
			$(".parallax-left-1").css({
				transform: translation,
			});
		}
		if (scroll > 700) {
			translation = "translateX(0vw)";
			$(".parallax-left-1").css({
				transform: translation,
			});
		}
		if (scroll < 900) {
			translation = "translateX(-100vw)";
			$(".parallax-left-2").css({
				transform: translation,
			});
		}
		if (scroll > 900) {
			translation = "translateX(0vw)";
			$(".parallax-left-2").css({
				transform: translation,
			});
		}
		if (scroll < 1100) {
			translation = "translateX(-100vw)";
			$(".parallax-left-3").css({
				transform: translation,
			});
		}
		if (scroll > 1100) {
			translation = "translateX(0vw)";
			$(".parallax-left-3").css({
				transform: translation,
			});
		}
	}

	/**********************************************/
	/* menu hide and show when scrolled
	/**********************************************/
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $(".menu-desktop").outerHeight();

	$(window).scroll( function(event) {
		didScroll = true;
	});
	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);

	function hasScrolled() {
		var st = $(this).scrollTop();

		// Make sure they scroll more than delta
	    if(Math.abs(lastScrollTop - st) <= delta)
	        return;
	    // If they scrolled down and are past the navbar, add class .nav-up.
	    // This is necessary so you never see what is "behind" the navbar.
	    if (st > lastScrollTop && st > navbarHeight){
	        // Scroll Down
	        $('.menu-desktop').removeClass('nav-down').addClass('nav-up');
	    } else {
	        // Scroll Up
	        if(st + $(window).height() < $(document).height()) {
	            $('.menu-desktop').removeClass('nav-up').addClass('nav-down');
			}
		}
		lastScrollTop = st;

		if (st > 0) {
			$('.module-menu').addClass('scrolled');
		}
		else {
			$('.module-menu').removeClass('scrolled');
		}
	}


	$(".popup").click(function(e) {
	    $(".popup").fadeOut();
	});

	/**********************************************/
	if ($.fn.skrollr) {
		skrollr.init({
			smoothScrolling: true,
			smoothScrollingDuration: 500,
			mobileDeceleration: 0.004,
			forceHeight: true,
			render: function(data) {
	            //Debugging - Log the current scroll position.
	            //console.log(data.curTop);
	        }
		});
	}


    $(document).ready(function () {
        if($('.errors').length) {
            $('html, body').animate({
                scrollTop: $('.errors').offset().top + 200
            }, 'slow');
        }
    });

    $(document).ready(function () {
		if($('.messages').length) {
			$('html, body').animate({
				scrollTop: $('.messages').offset().top + 200
			}, 'slow');
		}
    });

});
